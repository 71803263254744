<template>
  <div class="ci__ui">
    <div
      class="input-container"
      :class="{
        error: errors.length > 0
      }"
    >
      <input
        type="text"
        ref="input"
        v-maska
        :data-maska="mask"
        :readonly="!edit"
        :disabled="!edit"
        v-model="value"
      >

      <p
        class="input-container__placeholder"
        :class="{
          active:
            value?.length > 0 || isActive
        }"
      >
        {{ placeholder }}
      </p>

      <div
        v-click-outside="hide"
        class="input-container__country"
      >
        <div
          class="input-container__country__button"
          @click.stop="isActiveList = !isActiveList"
        >
          <img
            :src="selectCountry.flag"
            :alt="selectCountry.iso"
          >
        </div>

        <div
          v-if="isActiveList"
          class="input-container__country__list"
        >
          <div
            v-for="item in availableCoutry"
            :key="item.iso"
            :class="{
              active: selectCountry.iso === item.iso
            }"
            class="input-container__country__list__item"
            @click="changeCountry(item)"
          >
            <img
              :src="item.flag"
              loading="lazy"
            />

            {{ item.code }}
          </div>
        </div>
      </div>
    </div>

    <p
      v-for="(error, index) in errors"
      :key="index"
      class="ci__ui__error-text"
    >
      {{ $t(error) }}
    </p>
  </div>
</template>

<script setup>
import { vMaska } from 'maska'
import { ref, computed, onMounted } from 'vue'

// eslint-disable-next-line no-undef
const props = defineProps({
  edit: {
    type: Boolean,
    default: true
  },
  modelValue: {
    type: [String, Number],
    default: ''
  },
  isActive: {
    type: [Boolean, Number],
    default: false
  },
  placeholder: {
    type: String,
    required: true
  },
  autoFocus: {
    type: Boolean,
    default: false
  },
  errors: {
    type: Array,
    default () {
      return []
    }
  }
})

// eslint-disable-next-line no-undef
const emit = defineEmits(['update:modelValue'])

const input = ref(null)
const isActiveList = ref(false)

function hide () {
  isActiveList.value = false
}

function changeCountry (country) {
  selectCountry.value = country
  value.value = ''
  isActiveList.value = false

  setTimeout(() => {
    value.value = country.code
  }, 50)
}

const value = computed({
  get () {
    return props.modelValue
  },
  set (value) {
    emit('update:modelValue', value)
  }
})

const availableCoutry = ref(
  require('countries-phone-masks').filter(({ iso }) =>
    ['PL', 'KZ', 'UA', 'MD', 'BY', 'RU', 'UZ', 'TR'].includes(iso))
)
const selectCountry = ref(availableCoutry.value[0])

const mask = computed(() => {
  const { code, mask } = selectCountry.value

  if (typeof mask === 'object') {
    return `${code} ${mask[0].replace(/\d+(?:\.\d+)?/g, '#').replace(')#', ') #')}`
  }

  return `${code} ${mask?.replace(/\d+(?:\.\d+)?/g, '#')?.replace(')#', ') #')}`
})

onMounted(() => {
  value.value = selectCountry.value.code

  if (props.autoFocus) {
    input.value.focus()
  }
})
</script>

<style lang="scss">
.input-container {
  position: relative;

  .input-container__country {
    position: relative;
    width: 40px;

    &__button {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        max-height: 14px;
        max-width: 20px;
        margin: 0 auto;
        border-radius: 3px;
      }
    }

    &__list {
      position: absolute;
      top: 100%;
      right: -8px;
      max-height: 100px;
      width: 100px;
      margin-top: 5px;
      padding: 3px;
      display: flex;
      flex-direction: column;
      gap: 2px;
      background: #F0F2F8;
      border: 1px solid rgba(47, 87, 233, 1);
      border-radius: 5px;
      overflow-y: auto;
      z-index: 2;

      &__item {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 5px 4px;
        border: 1px solid transparent;
        border-radius: 4px;
        font-size: 12px;
        line-height: 100%;
        font-family: Medium;
        cursor: pointer;
        transition: .2s;

        &:hover {
          border: 1px solid rgba(47, 87, 233, .2);
        }

        &.active {
          border: 1px solid rgba(47, 87, 233, 1);
        }

        img {
          max-height: 20px;
          max-width: 20px;
          border-radius: 2px;
        }
      }
    }
  }
}

.vue-tel-input {
  position: relative;
  height: 100%;
  width: 100%;
  flex-direction: row-reverse;
  background: transparent;
  border: none;

  &.disabled {
    cursor: default;

    input {
      cursor: default;
    }
  }

  .vti__input {
    position: relative;
    top: 7px;
    background: transparent;
    padding: 0;
    color: rgb(32, 37, 57);
    font-size: 16px;
    line-height: 100%;
    font-family: Medium;
  }

  .vti__dropdown {
    padding: 0;
  }

  &:focus-within {
    box-shadow: none;
    border-color: none;
  }

  .vti__dropdown-list.below {
    top: 100%;
    // width: 100%;
    max-width: 250px;
    right: -8px !important;
    // left: -100% !important;
  }
}
</style>
